import { useState, useEffect, useCallback } from 'react'
import { Card, Button, Input, Alert, Modal, Timeline, Typography, Divider } from 'antd'
import { SendOutlined, DeleteOutlined } from '@ant-design/icons'
import Contract from '../common/Contract'
import { useLocation } from 'wouter'
import moment from 'moment'

const { Text } = Typography
const BLOCKCHAIN_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER

export default function Read ({ address, tokenId }) {
  const [envelop, setEnvelop] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [sending, setSending] = useState(false)
  const [burning, setBurning] = useState(false)
  const [displayForward, setDisplayForward] = useState(false)
  const [, setLocation] = useLocation()

  const handleForward = useCallback(async (recipient) => {
    await setSending(true)
    try {
      await Contract.transferFrom(address, recipient, tokenId)
      await setLocation('/mailbox')
    } catch (err) {
      setError(err.message)
    }

    await setSending(false)
  }, [address, tokenId, setLocation])

  const askForwardRecipient = useCallback(() => {
    let recipient = ''
    const handleRecipientChange = (e) => { recipient = e.target.value }

    Modal.confirm({
      title: 'Transfer Message',
      onOk: () => handleForward(recipient),
      content: <Input onChange={handleRecipientChange} placeholder='Recipient 0x…' allowClear loading={sending} />
    })
    setDisplayForward(!displayForward)
  }, [displayForward, setDisplayForward, handleForward, sending])

  const handleDelete = useCallback(async () => {
    await setBurning(true)
    try {
      await Contract.burn(tokenId)
      await setLocation('/mailbox')
    } catch (err) {
      setError(err.message)
    }
    await setBurning(false)
  }, [setLocation, tokenId])

  const openMessage = async (tokenId) => {
    await setLoading(true)
    const { decoded: { 0: owner } } = await Contract.ownerOf(tokenId)
    const { decoded: { 0: tokenURI } } = await Contract.tokenURI(tokenId)
    const text = String(tokenURI || '').replace('text://', '')

    const events = await Contract.fetchHistory(tokenId)
    await setEnvelop({ owner, tokenURI, text, events })
    await setLoading(false)
  }

  useEffect(() => {
    openMessage(tokenId)
  }, [tokenId])

  function Extra () {
    if (!envelop.owner) {
      return <></>
    }

    return (
      <>
        <Button key='burn' type='link' onClick={handleDelete} loading={burning}><DeleteOutlined /></Button>
        <Button type='link' onClick={askForwardRecipient} loading={sending}><SendOutlined /></Button>
      </>
    )
  }

  return (
    <Card
      title='Message'
      extra={<Extra />}
      loading={loading}
      hoverable
    >
      {!!error && <Alert message={error} type='error' closable />}
      <Card.Meta
        description={<pre style={{ whiteSpace: 'break-spaces' }}>{envelop.text}</pre>}
      />

      <Divider dashed />

      <Timeline mode='left'>
        {envelop?.events?.map((event) => <TimelineEvent key={event.txID} {...event} />)}
      </Timeline>

    </Card>
  )
}

function TimelineEvent ({ timestamp, meta, name, from, inputs, values }) {
  const mintEvent = values.from === '0x0000000000000000000000000000000000000000'

  if (mintEvent) {
    return (
      <Timeline.Item
        color='green'
      >
        <Text>
          <Typography.Link type='secondary' href={`${BLOCKCHAIN_EXPLORER}/transactions/${meta.txID}`} target='_blank' rel='noreferrer'>{moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')} </Typography.Link>
          <Typography.Link code href={`${BLOCKCHAIN_EXPLORER}/accounts/${from}`} target='_blank' rel='noreferrer'>{from}</Typography.Link>
          <Text strong> has written to </Text>
          <a href={`${BLOCKCHAIN_EXPLORER}/accounts/${values.to}`} target='_blank' rel='noreferrer'><Typography.Link code>{values.to}</Typography.Link></a>
        </Text>
      </Timeline.Item>
    )
  }

  return (
    <Timeline.Item>
      <Text>
        <Typography.Link type='secondary' href={`${BLOCKCHAIN_EXPLORER}/transactions/${meta.txID}`} target='_blank' rel='noreferrer'>{moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')} </Typography.Link>
        <Text strong> forwarded to </Text>
        <Typography.Link code href={`${BLOCKCHAIN_EXPLORER}/accounts/${from}`} target='_blank' rel='noreferrer'>{values.to}</Typography.Link>
      </Text>
    </Timeline.Item>
  )
}
