export default function Signer ({ node, connex }) {
  return { send }

  async function send (clauses = [], options = {}) {
    if (!clauses.filter(clause => !!clause).length) {
      return
    }

    // 0.99 VET
    // clauses[0].value = 90000000000000000

    const { txid: id } = await connex.vendor.sign('tx', clauses).request()
    return id
  }
}
