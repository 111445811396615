import 'antd/dist/antd.css'
import { Button, Card, Row, Col, Alert } from 'antd'
import Connex from '@vechain/connex'

export default function SignIn ({ onSignIn }) {
  const signIn = async () => {
    const connex = new Connex({
      node: process.env.REACT_APP_CONNEX_NETWORK_URL,
      network: process.env.REACT_APP_CONNEX_NETWORK_TYPE
    })
    const { annex } = await connex.vendor.sign('cert', {
      purpose: 'identification',
      payload: {
        type: 'text',
        content: 'Please select your account'
      }
    })
      .request()

    await onSignIn(annex.signer)
  }

  return (
    <Card title='Identify yourself' hoverable>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Alert message='Please identify yourself to publish messages on the blockchain.' type='warning' />
        </Col>
        <Col xs={24}>
          <Button block onClick={signIn} type='primary'>Identify</Button>
        </Col>
      </Row>
    </Card>

  )
}
