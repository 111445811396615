import 'antd/dist/antd.css'
import { useState, useEffect, useCallback } from 'react'
import { Switch, Route, useLocation } from 'wouter'
import { Typography, Tabs, Badge } from 'antd'
import { useLocalStorage } from 'react-use'
import Create from './Create'
import Read from './Read'
import Mailbox from './Mailbox'
import SignIn from './SignIn'
import Withdraw from './Withdraw'
import Contract from './common/Contract'

const { Link } = Typography
const { TabPane } = Tabs

function App () {
  const [address, setAddress, removeAddress] = useLocalStorage('address')
  const [readTokenIds, setReadTokenIds] = useLocalStorage('readTokenIds', [])
  const [location, setLocation] = useLocation()
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  const handleSignOut = async () => {
    await removeAddress()
  }

  const handleSignIn = async (address) => {
    await setAddress(address)
  }

  const checkUnreadMessages = useCallback(async () => {
    let unreadMessageCount = 0
    const tokenIds = new Set(readTokenIds)
    const { decoded: { 0: balance } } = await Contract.balanceOf(address)
    for (let tokenIndex = 0; tokenIndex < balance; tokenIndex += 1) {
      const { decoded: { 0: tokenId } } = await Contract.tokenOfOwnerByIndex(address, tokenIndex)
      if (!tokenIds.has(tokenId)) {
        unreadMessageCount += 1
      }
    }
    await setUnreadMessageCount(unreadMessageCount)
  }, [address, readTokenIds])

  const MarkMessageAsRead = useCallback(({ tokenId }) => {
    if (!readTokenIds.includes(tokenId)) {
      setReadTokenIds([...readTokenIds, tokenId])
    }

    return <></>
  }, [readTokenIds, setReadTokenIds])

  useEffect(() => {
    checkUnreadMessages()
  }, [checkUnreadMessages])

  return (
    <>
      <Tabs activeKey={location} onChange={setLocation}>
        <TabPane tab='New Message' key='/' />
        <TabPane tab={<>Mailbox <Badge count={unreadMessageCount} /></>} key='/mailbox' />
      </Tabs>
      <Switch>
        <Route path='/'>
          {!address && <SignIn onSignIn={handleSignIn} />}
          {!!address && <Create address={address} />}
        </Route>

        <Route path='/message/:tokenId'>
          {params => (
            <>
              <Read address={address} {...params} />
              <MarkMessageAsRead {...params} />
            </>
          )}
        </Route>

        <Route path='/mailbox'>
          <Mailbox address={address} />
        </Route>
        <Route path='/withdraw'>
          <Withdraw address={address} />
        </Route>
      </Switch>

      {!!address && (
        <>
          <br />
          <center>
            <Link block onClick={handleSignOut}>Sign Out</Link>
          </center>
        </>
      )}
    </>
  )
}

export default App
