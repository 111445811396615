export default function Contract ({ connex, submitTransaction, address, abi }) {
  const Contract = {
    Account: connex.thor.account(address)
  }
  const Events = {
    onBlock: {
      callbacks: []
    }
  }

  const defineViewFunction = (method) => (...args) => Contract.Account.method(method).call(...args)
  const defineEventFunction = (method) => () => Contract.Account.event(method)
  const defineClause = (method) => (...args) => Contract.Account.method(method).asClause(...args)
  const defineTransaction = (method) => (...args) => {
    const clause = defineClause(method)(...args)
    return submitTransaction([clause])
  }

  abi.forEach(abiFunc => {
    if (abiFunc.stateMutability === 'view') {
      Contract[abiFunc.name] = defineViewFunction(abiFunc)
    } else if (abiFunc.type === 'function') {
      Contract[abiFunc.name] = defineTransaction(abiFunc)
    } else if (abiFunc.type === 'event') {
      Contract[abiFunc.name] = defineEventFunction(abiFunc)
      Events[abiFunc.name] = {
        abi: abiFunc,
        callbacks: []
      }
    } else {
      Contract[abiFunc.name] = {}
    }
  })

  Contract.addEventListener = (eventName, callback) => {
    if (!Events[eventName]) {
      throw new Error(`Event ${eventName} not found`)
    }
    Events[eventName].callbacks.push(callback)
    return callback
  }

  Contract.removeEventListener = (eventName, callbackToRemove) => {
    if (!Events[eventName]) {
      throw new Error(`Event ${eventName} not found`)
    }

    Events[eventName].callbacks = Events[eventName].callbacks.filter(callback => callback !== callbackToRemove)
  }

  Contract.fireEvent = (eventName, data) => {
    Events[eventName].callbacks.forEach(callback => callback(data))
  }

  Contract.addEventListener('onBlock', async ({ _previousBlock, ...block }) => {
    const eventNames = Object.keys(Events)
    for (const eventName of eventNames) {
      if (!Events[eventName].abi) {
        continue
      }

      const events = await fetchEventData({
        range: {
          unit: 'block',
          from: (_previousBlock.number || 0) + 1,
          to: block.number || 1
        },
        ...Events[eventName]
      })

      events.forEach(data => Contract.fireEvent(eventName, data))
    }
  })

  async function fetchEventData ({ range, abi }) {
    return Contract.Account
      .event(abi)
      .filter([])
      .order('asc')
      .range(range)
      .apply(0, 256)
  }

  return Contract
}
