import { useState, useCallback } from 'react'
import { useLocation } from 'wouter'
import { Input, Button, Card, Row, Col, Alert } from 'antd'
import Contract from '../common/Contract'

export default function Create ({ address: defaultRecipient }) {
  const [message, setMessage] = useState('')
  const [recipient, setRecipient] = useState(defaultRecipient)
  const [minting, setMinting] = useState(false)
  const [, setLocation] = useLocation()

  const handleMessageChange = ({ target }) => setMessage(target.value)
  const handleRecipientChange = ({ target }) => setRecipient(target.value)
  const handleMint = useCallback(async () => {
    await setMinting(true)
    const { outputs } = await Contract.mint(recipient, `text://${message}`)
    await setMinting(false)

    const tokenId = parseInt(outputs[0].events[0].topics[3], 16)
    setLocation(`/message/${tokenId}`)
  }, [recipient, message, setLocation])

  return (
    <Card title='Write new Message' hoverable>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Alert message='Messages published on the Blockchain are public and can not be removed. Be careful what you publish.' type='warning' closable />
        </Col>
        <Col xs={24}>
          <Input addonBefore='Send to' onChange={handleRecipientChange} value={recipient} placeholder='Recipient address 0x…' />
        </Col>
        <Col xs={24}>
          <Input.TextArea onChange={handleMessageChange} rows={4} placeholder='Write your message here' maxLength={1024} showCount />
        </Col>
        <Col xs={24}>
          <Button block onClick={handleMint} type='primary' loading={minting}>send message</Button>
        </Col>
      </Row>
    </Card>
  )
}
