import Network from './vechain-contract'
import abi from '../res/NFT.abi.json'

const contract = Network({
  node: process.env.REACT_APP_CONNEX_NETWORK_URL,
  network: process.env.REACT_APP_CONNEX_NETWORK_TYPE
})
  .buildContract({ address: process.env.REACT_APP_CONTRACT_ADDRESS, abi })

contract.fetchHistory = async (tokenId) => {
  const transferEventAbi = abi.find(({ type, name }) => type === 'event' && name === 'Transfer')

  const transferEvent = contract.Account.event(transferEventAbi)

  const perPage = 256
  let page = 0
  const events = []

  do {
    const rawEvents = await transferEvent
      .filter([{ tokenId }])
      .order('desc')
      .apply(page * perPage, perPage)

    rawEvents.forEach(event => {
      const timelineEvent = {
        from: event.meta.txOrigin,
        meta: event.meta,
        name: transferEventAbi.name,
        inputs: transferEventAbi.inputs,
        values: event.decoded,
        timestamp: event.meta.blockTimestamp
      }
      events.push(timelineEvent)
    })

    if (rawEvents.length < perPage) {
      break
    }

    page += 1
  } while (true)

  return events
}

export default contract
