import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Row, Col } from 'antd'

ReactDOM.render(
  <React.StrictMode>
    <Row>
      <Col xs={24} md={{ span: 12, push: 6, pull: 6 }}>
        <App />
      </Col>
    </Row>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
