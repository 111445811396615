import Connex from '@vechain/connex'
import Contract from './Contract'
import Transaction from './Transaction'

export default function Network ({ node, network, wallet, delegateUrl } = {}) {
  const connex = new Connex({ node, network })

  const buildContract = ({ abi, ...args } = {}) => Contract({ abi, connex, submitTransaction, ...args })

  async function submitTransaction (clauses = []) {
    const signer = Transaction({ connex, network, node, wallet, delegateUrl, clauses })
    const txid = await signer.send(clauses)
    if (txid) {
      return waitForTransactionId(txid)
    }
  }

  async function waitForTransactionId (id) {
    let transaction
    do {
      await connex.thor.ticker().next()
      transaction = await connex.thor.transaction(id).getReceipt()
    } while (!transaction)

    if (transaction.reverted) {
      throw new Error('Transaction was reverted')
    }

    return transaction
  }

  return { buildContract, submitTransaction, connex }
}
