import { useState } from 'react'
import { Card, Button } from 'antd'
import Contract from '../common/Contract'

export default function Withdraw ({ address }) {
  const [loading, setLoading] = useState(false)

  const handleWithdraw = async () => {
    await setLoading(true)
    await Contract.withdrawBalanceTo(address)
    await setLoading(false)
  }
  return (
    <Card title={address}>
      <Button onClick={handleWithdraw} block loading={loading}>withdraw</Button>
      test
    </Card>
  )
}
