import { useState, useEffect } from 'react'
import { Table } from 'antd'
import Contract from '../common/Contract'
import { Link } from 'wouter'

const columns = [
  { title: '#', dataIndex: 'tokenId', fixed: true, width: 64, render: (text, message) => <Link to={`/message/${message.tokenId}`}>#{text}</Link> },
  { title: 'Message', dataIndex: 'text', fixed: true, ellipsis: true, render: (text, message) => <Link to={`/message/${message.tokenId}`}>{text}</Link> }
]

export default function List ({ address }) {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)

  const readMailbox = async (owner) => {
    try {
      await setLoading(true)

      const messages = []
      const { decoded: { 0: balance } } = await Contract.balanceOf(owner)
      for (let tokenIndex = 0; tokenIndex < balance; tokenIndex += 1) {
        const { decoded: { 0: tokenId } } = await Contract.tokenOfOwnerByIndex(owner, tokenIndex)
        const { decoded: { 0: tokenURI } } = await Contract.tokenURI(tokenId)
        const text = String(tokenURI).replace('text://', '')
        messages.push({ tokenId, tokenURI, owner, text })
      }
      await setMessages(messages.sort((msg1, msg2) => msg1.tokenId > msg2.tokenId ? -1 : 1))
    } catch (err) {
      console.log(err)
    }

    await setLoading(false)
  }

  useEffect(() => {
    readMailbox(address)
  }, [address])

  return <Table columns={columns} dataSource={messages} loading={loading} />
}
